<template>
    <WalletLayout>
        <div class="row gy-3">
            <div class="col-md-12">
                <TopHeading :data="topHeading" />
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-body shadow-sm">
                        <OrdersHistoryTable :data="transactionData" @updateResponse="completeOrders" />
                        <!-- <div class="row align-items-center gap-3">
                            <div class="col">
                                <div class="input-group search-input">
                                    <span class="input-group-text"><i class="fa-solid fa-magnifying-glass"></i></span>
                                    <input type="search" v-model="search" ref="searchInput" v-on:keyup="searchData()"
                                        placeholder="Search" class="form-control ">
                                </div>
                            </div>
                            <div class="col-12">
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </WalletLayout>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
import WalletLayout from "@/layouts/WalletLayout.vue";
import ApiClass from "@/api/api";
import TopHeading from "@/components/ui/TopHeading.vue";
import OrdersHistoryTable from "@/components/Orders/OrdersHistoryTable.vue";
export default {
    name: "TransactionHistory",
    components: {
        OrdersHistoryTable,
        TopHeading,
        WalletLayout
        // DatePicker
    },
    data() {
        return {
            topHeading: {
                heading: 'Orders & Trade',
                subheading: 'Track past orders & trade history',
                buttons: [
                    // { name: 'Spot Wallet', link: '/wallet/spot', class: 'text-primary border-primary' },
                    // {name:'Deposit', link:'/wallet/deposit', class:'btn-primary text-white'}
                ],
            },
            transactionData: {
                res: [],
                balances: [],
                skeletonLoad: false,
                loading: false,
                active_tab: 'completed',
                from_page: 0,
                last_page: 0,
                current_page: 1,
                tableHeading: ['Pair', 'Type', 'Order Type', 'Price', 'Quantity', 'Date', 'Status'],
            },
        }
    },
    methods: {
        // searchData() {
        //     var search = document.getElementById("searchData").value.toUpperCase();
        //     if (search) {
        //         this.TransactionList = this.res.filter((e) => e.user_wallet_address.toUpperCase().includes(search));
        //     } else {
        //         this.TransactionList = this.res.filter((e) => e);
        //     }
        // },

        async completeOrders(page) {
            let obj = this.transactionData;
            page == 1 ? obj.skeletonLoad = true : obj.loading = true;
            var url =
                "orders/get?type=completed&page=" + page + "&per_page=10";
            await ApiClass.getNodeRequest(url, true).then((response) => {
                if (response.data.status_code == 1) {
                    obj.skeletonLoad = false;
                    obj.loading = false;

                    obj.current_page = response.data.data.current_page;
                    obj.last_page = response.data.data.last_page;
                    let newArray = [...obj.balances, ...response.data.data.data];
                    obj.balances = obj.res = this.$helpers.getUniqArrObj(newArray);
                }
            });
        },
    },
    async mounted() {
        this.completeOrders(1)
    }
};
</script>
<style scoped>
#txn-nav-top .dropdown-menu li {
    cursor: pointer;
}

#txn-nav-top .date-option button:hover,
#txn-nav-top .filter-option:hover {
    color: var(--primary)
}

#txn-nav-top .date-option button,
#txn-nav-top .filter-option,
#txn-nav-top .date-option .dropdown-menu .dropdown-item {
    color: var(--text)
}

#txn-nav-top .date-option .btn.show {
    background-color: inherit !important;
}

.fliter-option-title {
    text-transform: capitalize;
}

#dateModal .modal-sm {
    max-width: min-content;
}

#btn-check-label {
    font-size: 0.8rem !important;
    font-weight: 500;
    color: var(--text);
    background-color: var(--bg5);
    border-radius: 2rem;
    border: none !important;
}

.filter-check:checked+.btn {
    background-color: var(--primary) !important;
    border: none !important;
    color: rgb(253, 253, 253) !important;
}

.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: none;
}
</style>